<template>
    <div>
        <dmx-title :bc-items="bcItems">{{ $t('subscriptiontemplate_title') }}</dmx-title>

        <b-row align-v="center" class="mb-1">
            <b-col>
                {{ pagination.totalListCount }} {{$t('subscriptiontemplate_subscriptions_found')}}
                <span class="ml-3">
                    Show: <b-link @click="setPerPage(10)" :style="((options.perPage == 10) ? 'font-weight: bold;' : ' ')">10</b-link> |
                    <b-link @click="setPerPage(20)" :style="((options.perPage == 20) ? 'font-weight: bold;' : ' ')">20</b-link> |
                    <b-link @click="setPerPage(1000)" :style="((options.perPage == 1000) ? 'font-weight: bold;' : ' ')">All</b-link>
                </span>
            </b-col>
            <b-col>
                <b-input v-model="searchField"
                         @input="searchWithText()"
                         :loading="searchFieldIsLoading"
                         :placeholder="$t('common_search')">
                </b-input>
            </b-col>
            <b-col align="right">
                <!-- This v-if should change with the amount of Types that exists -->
                <b-button variant="success" v-if="subscriptionTemplateList.length != 1" @click="newSubTemplate()">
                    <b-icon icon="plus"></b-icon>
                    {{ $t('common_create') }}
                </b-button>
                <b-button style="cursor:default" variant="success" v-if="subscriptionTemplateList.length == 1" disabled title="Can create more then available types (1)">
                    <b-icon icon="plus"></b-icon>
                    {{ $t('common_create') }}
                </b-button>
            </b-col>
        </b-row>
        <b-table id="devices-table"
                 thead-class="dmx-thead-block"
                 tbody-tr-class="dmx-pointer-element"
                 :busy="loading"
                 outlined
                 striped
                 bordered
                 hover
                 :items="subscriptionTemplateList"
                 :fields="headers"
                 @row-clicked="editSubTemplate"
                 >
        </b-table>

        <div>
            <b-pagination align="center" v-model="options.currentPage"
                          :total-rows="pagination.totalListCount"
                          :per-page="options.perPage"
                          aria-controls="subtemp-table">
            </b-pagination>
        </div>
    </div>
</template>

<style scoped>

</style>

<script>
    import subscriptionService from '@/services/subscriptionService';
    import router from '@/router';
    export default {
        name: "SubscriptionTemplateList",
        data: () => ({
            search: '',
            loading: true,
            options: {
                perPage: 10,
                currentPage: 1,
            },

            //flags
            searchFieldIsLoading: false,

            // values
            searchField: '',
            subscriptionTemplateList: [],

            // pagination
            pagination: {
                totalListCount: 0
            },
            filterTableHeadName: '',
            timeoutId: 0,
            bcItems: [
                {
                    text: 'subscription templates',
                    href: '/SubscriptionTemplateList',
                    active: true,
                }
            ]
        }),
        computed: {
            headers() {
                return [
                    {
                        label: this.$t('common_id'),
                        align: 'start',
                        sortable: true,
                        key: 'id',
                    },
                    {
                        label: 'Name',
                        align: 'start',
                        sortable: true,
                        key: 'name',
                    },
                    {
                        label: 'Price',
                        align: 'start',
                        sortable: true,
                        key: 'price',
                    },
                    {
                        label: 'Currency',
                        align: 'start',
                        sortable: true,
                        key: 'currency.currencyCode',
                    },
                    {
                        label: 'Type',
                        align: 'start',
                        sortable: true,
                        key: 'subscriptionName',
                    },
                ]
            }
        },
        methods: {
            newSubTemplate() {
                router.push({ name: 'subTemplateEdit', params: { id: 0 } });
            },
            editSubTemplate(e) {
                router.push({ name: 'subTemplateEdit', params: { id: parseInt(e.id) } });
            },
            setPerPage(num) {
                this.options.currentPage = 1;
                this.options.perPage = num;
            },
            searchWithText() {
                this.searchFieldIsLoading = true;

                if (this.timeoutId > 0) {
                    clearTimeout(this.timeoutId);
                }
                this.timeoutId = window.setTimeout(() => {
                    this.getSubscriptionTemplateList();
                }, 600);
            },

            filterByTableHeader(tableHeaderName) {
                this.filterTableHeadName = tableHeaderName;
                this.getSubscriptionTemplateList();
            },
            getSubscriptionTemplateList() {
                subscriptionService.getSubscriptionTemplateListWithoutGate("GetAllSubscriptionTemplates", {
                    Count: this.options.perPage,
                    SubscriptionTemplate: [],
                    SearchTerm: this.searchField,
                    Page: this.options.currentPage
                }).then((data) => {
                    this.subscriptionTemplateList = data;
                    this.searchFieldIsLoading = false;
                    this.options.currentPage = data.page;
                    this.pagination.totalListCount = data.totalListCount;
                    this.loading = false
                })
                .catch(function (error) {
                    alert(error);
                });
            }
        },
        mounted() {
                this.getSubscriptionTemplateList();
        }
    }
</script>
